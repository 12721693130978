//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cookie from "@/storage/cookies";
import moment from "moment";
import { Unit } from "@/pages/Home/InquiryPrice/Unit.js";
import { setColumnDataColor, setCellStyle } from "@/components/table/style";
import { apiHome } from "@/api/apiHome";
import { apiInquiryPrice } from "@/api/apiInquiryPrice";
import env from "../../../../../env";
const userApi = env[process.env.environment].VUE_APP_APIUSER; // 个人中心
import { fnc } from "@/pages/Home/InquiryPrice/fnc.js";
// import MapAddress from "@/pages/Inquiry/page/goodsAsk/components/map/ResMapAddress.vue";
import UploadfileItem from "@/pages/Inquiry/page/goodsAsk/components/UploadfileItem.vue";
export default {
  name: "GoodsAsk",
  components: {
    // MapAddress,
    UploadfileItem,
    AddressSeclect: () =>
      import("@/pages/Home/InquiryPrice/components/AddressSeclect.vue")
  },
  props: {
    // enquiryDetails: {
    //   type: Object
    // },
    formLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      resetFlag: true,
      enquiryDetails: {},
      noHidden: true,
      PriseLoading: false,
      wrapHeight: 0,
      NoData: false,
      itemDepotAddDialogFormVisible: false,
      mapData: {},
      IsType: true,
      mapAMap: null,
      geocoder: null,
      markerMap: null,
      IsPerson: false,
      centerValue: {
        lng: "",
        lat: ""
      },
      center: [104.04522, 30.54698],
      positionAddress: {},
      isFullScreen: false,
      mapVisible: false,
      imgLoad: false,
      token: cookie.cookieRead("token"),
      // uploadUrl: `${process.env.VUE_APP_API}/sgb-zhaocai/fileUpload`,
      uploadUrl: userApi + "/file/add",
      accept: ".rar,.zip,.doc,.docx,.pdf,.jpg",
      selectfileId: "",
      OwnAddress: {
        lng: 0,
        lat: 0
      },
      setColumnDataColor,
      setCellStyle,
      mapVisible: false,
      formType: 0, // 0 新增 1 编辑用户中心 2编辑pc商品 3编辑pc商砼
      title: "发布询价单",
      addSuccessDialogVisible: false,
      action: process.env.VUE_APP_APIUSER + "/file/add",
      form: {
        publishProvince: "",
        publishProvinceName: "",
        radio: 1,
        check: [],
        shop: "",
        purchaseVol: [],
        brandId: [],
        strongLevel: [],
        unit: [],
        attr: {},
        config: [],
        deliveryMethod: []
      },
      loading: false,
      subLoading: false,
      shopOptionList: [],
      shopList: [],
      brandList: [],
      propertyList: [],
      unitList: [],
      addressList: [],
      shangTong: [],
      classificationsOptions: [],
      admixtureFactList: [],
      strongFactList: [],
      tdList: [0],
      addressOptions: [],
      classificationsConfig: {
        value: "id",
        label: "name"
      },
      dialogImageUrl: "",
      selectedOptions: [],
      selectedAdmix: [],
      cascaderKey1: 0,
      cascaderKey2: 0,
      dialogVisible: false,
      formParams: {
        cityName: "",
        cityNo: "",
        concreteList: [],
        contactNumber: "",
        contacts: "",
        qualityDesc: "",
        featuresDesc: "",
        deadlineTime: "",
        describe: "",
        districtName: "",
        districtNo: "",
        id: 0,
        img: "",
        // "inviteCompany": "",
        itemList: [],
        provinceName: "",
        provinceNo: "",
        publishProvince: "",
        quotationMethod: "",
        receivingTime: "",
        transactionMethod: 1,
        type: 2,
        SaleType: 1,
        detailAddress: "",
        latitude: 0,
        longitude: 0,
        taxRate: "",
        deliveryRequire: 1,
        loadingRequire: 1,
        unloadRequire: 1,
        // payWay: 1,
        filePath: [],
        // filePath: '',
        inviteCompanyList: []
      },
      formValidate: false,
      phoneValidate: true,
      numValidate: true,
      areaListThree: [],
      fileList: [],
      endDateOpt: {
        disabledDate: time => {
          let time1 = moment(time.getTime()).format("YYYY/MM/DD HH:mm:ss");
          let time2 = moment(Date.now())
            .subtract(1, "days")
            .format("YYYY/MM/DD HH:mm:ss");
          return time1 < time2;
        }
      },
      receivingTimeDateOpt: {
        disabledDate: time => {
          let time1 = moment(time.getTime()).format("YYYY/MM/DD HH:mm:ss");
          let time2 = moment(Date.now())
            .subtract(1, "days")
            .format("YYYY/MM/DD HH:mm:ss");
          return time1 < time2;
        }
      },
      detailId: "",
      details: {},
      detailPc: {}
    };
  },
  watch: {
    tdList: {
      handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    },
    enquiryDetails: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.detailPc = JSON.parse(JSON.stringify(newVal));
          this.pcInit();
        }
      },
      immediate: true,
      deep: true
    },
    "formParams.receivingTime": function(newVal) {
      let timeStr = "23:59:59";
      let nowStr = moment(newVal).format("YYYY/MM/DD");
      // this.formParams.receivingTime = nowStr + ' ' + timeStr
    },
    "formParams.SaleType": {
      handler(newVal, oldVal) {
        if (newVal == 3) {
          this.getSupplierMember();
        }
      },
      immediate: true,
      deep: true
    },
    userInfo: {
      handler(val) {
        if (val) {
          let boll1 = val.userRole == 1; //0个人1企业
          let boll2 = val.userRole == 0 && val.relevance; //true关联false未关联企业
          if ((boll1 || boll2) && val.companyNo && val.companyNo != "0") {
            this.IsPerson = true;
          } else {
            this.IsPerson = false;
          }
        } else {
          this.IsPerson = false;
        }
      },
      immediate: true,
      deep: true
    },
    "fileList.length"(val) {
      // this.$nextTick(() => {
      //   this.wrapHeight = this.$refs.contentWrap.getBoundingClientRect().height;
      // });
    }
  },
  methods: {
    // 删除附件
    cancelFile(file) {
      const index = this.fileList.findIndex(item => {
        return item.url === file.url;
      });
      this.fileList.splice(index, 1);
    },
    onSuccess(response, file, fileList) {
      console.log("onSuccess", response);
      if (response.code !== 200) {
        this.$message.error(response.msg);
      } else {
        this.imgLoad = false;
        this.$message({
          message: "上传成功!",
          type: "success"
        });
        this.fileList = fileList.map(item => {
          if (item.response) {
            return {
              fileName: item.name,
              size: item.size,
              status: item.status,
              uid: item.uid,
              url: item.response.data
            };
          } else {
            return item;
          }
        });
      }
    },
    onExceed() {
      this.$message.error("附件总个数不能超过10个");
    },
    //选择目的地
    destinationAddressObj(data) {
      this.mapData = Object.assign({}, data);
      this.formParams.longitude = data.addressObj.lng;
      this.formParams.latitude = data.addressObj.lat;
      this.$set(this.formParams, "provinceNo", data.PriArray[0]);
      this.$set(this.formParams, "cityNo", data.PriArray[1]);
      this.$set(this.formParams, "districtNo", data.PriArray[2]);
      this.$set(this.formParams, "provinceName", data.PriData.province);
      this.$set(this.formParams, "cityName", data.PriData.city);
      this.$set(this.formParams, "districtName", data.PriData.district);
    },
    endCustomAddr(addr) {
      this.$set(this.formParams, "detailAddress", addr);
    },
    sendSuccess() {
      this.addSuccessDialogVisible = false;
      this.closeForm();
    },
    closeForm() {
      this.resetForm();
      // this.$emit("closeInquiryPriceDialog");
    },
    resetForm() {
      (this.tdList = [0]),
        (this.form = {
          publishProvince: "",
          publishProvinceName: "",
          radio: 1,
          check: [],
          shop: "",
          purchaseVol: [],
          brandId: [],
          strongLevel: [],
          unit: [],
          attr: {},
          config: [],
          deliveryMethod: []
        });
      this.shopList = [];
      this.formParams = {
        cityName: "",
        cityNo: "",
        concreteList: [],
        contactNumber: "",
        contacts: "",
        qualityDesc: "",
        featuresDesc: "",
        // "deadlineTime": "",
        describe: "",
        districtName: "",
        districtNo: "",
        id: 0,
        img: "",
        // "inviteCompany": "",
        itemList: [],
        provinceName: "",
        provinceNo: "",
        publishProvince: "",
        quotationMethod: "",
        receivingTime: "",
        transactionMethod: 1,
        type: 2,
        SaleType: 1,
        detailAddress: "",
        latitude: 0,
        longitude: 0,
        taxRate: "",
        deliveryRequire: 1,
        loadingRequire: 1,
        unloadRequire: 1,
        // payWay: 1,
        filePath: [],
        // filePath: '',
        inviteCompanyList: []
      };
      this.fileList = [];
      this.selectedOptions = []
    },
    submitUpload(val) {
      this.selectfileId = val;
    },
    async myPoint() {
      this.mapVisible = true;
    },
    getPosition(obj, center) {
      this.$set(this.centerValue, "lng", obj.point.lng);
      this.$set(this.centerValue, "lat", obj.point.lat);
      this.positionAddress = obj;
      // this.getCity()
      this.center = center;
      this.formParams.detailAddress = this.positionAddress.value;
      if (this.center) {
        this.formParams.longitude = this.center[0];
        this.formParams.latitude = this.center[1];
      }
      // this.handleTab(this.tabType)
    },
    close() {
      this.mapVisible = false;
    },
    closeDialog() {
      this.resetForm();
      this.$emit("closeInquiryPriceDialog");
    },
    // 获取发布地区列表
    async setProvinceNoOptions() {
      let res = await apiHome.getCurrentProvince();
      this.addressList = res.data.data;
    },
    //获取供应商会员
    async getSupplierMember() {
      this.PriseLoading = true;
      let res = await apiInquiryPrice.getSupplierMember();
      this.PriseLoading = false;
      if (res && res.data && res.data.code == 200) {
        if (res.data.data && res.data.data.length > 0) {
          // this.shopList.push(...res.data.data);
          // let hash = {}; //去重
          // let arr = this.shopList.reduce((preVal, curVal) => {
          //   hash[curVal.no]		//id就是数组中的id字段
          //     ? ""
          //     : (hash[curVal.no] = true && preVal.push(curVal));
          //   return preVal;
          // }, []);
          // this.shopList=arr
          this.shopList = res.data.data;
          this.NoData = false;
        } else {
          this.shopList = [];
          this.NoData = true;
        }
      } else {
        this.shopList = [];
        this.NoData = true;
      }
    },
    // 获取分类
    async getClassificationsData() {
      let res = await apiInquiryPrice.getProducts();
      ++this.cascaderKey1;
      if (res.data.code == 200 && res.data.data.length > 0) {
        this.classificationsOptions = res.data.data;
      } else {
        this.classificationsOptions = [];
      }
      // 编辑-初始化
      /*if(this.formType === 1){
          this.$nextTick(()=>{
            this.init()
          })
        }
        if(this.formType === 2 || this.formType === 3){
          this.$nextTick(()=>{
            this.pcInit()
          })
        }*/
    },
    // 获取品牌信息
    async getBrandData(id, idx) {
      let res = await apiInquiryPrice.fetchBrand(id);
      // this.brandList[idx] = res.data.data
      this.$set(this.brandList, idx, res.data.data);
    },
    // 获取属性选择
    async getProperty(id, idx) {
      if (JSON.stringify(this.form.attr) !== "{}") {
        for (let i in this.form.attr) {
          let index = i.split("-");
          if (index == idx) {
            delete this.form.attr[i];
          }
        }
      }
      let res = await fnc.getProperty(id);
      this.$set(this.propertyList, idx, res);
    },
    // 获取单位
    async getUnitListData() {
      let res = await apiInquiryPrice.fetchUnit();
      this.unitList = res.data;
    },
    // 获取强度列表
    async getLevetData(idx) {
      let business = await apiInquiryPrice.concreteStrongAll();
      this.$set(this.strongFactList, idx, business.data.result);
    },
    // 获取外加剂列表
    async getAdditiveData(idx) {
      let business = await apiInquiryPrice.concreteAdmixtureAll();
      let data = this.setGroup(business.data.result);
      ++this.cascaderKey2;
      this.$set(this.admixtureFactList, idx, data);
    },
    // 外加剂选择
    admixChange(value, idx) {
      if (!value) {
        return;
      }
      this.formParams.itemList[idx].admixtureFactList = [];
      if (value.length) {
        value.forEach((item, index) => {
          if (item.length === 1) {
            this.formParams.itemList[idx].admixtureFactList.push({
              admixtureName: item[0],
              // admixtureName: item[0].admixtureName,
              spec: ""
            });
          } else if (item.length === 2) {
            this.formParams.itemList[idx].admixtureFactList.push({
              admixtureName: item[1].split("-")[0],
              spec: item[1].split("-")[1]
            });
          }
        });
      }
    },
    // 选择发布地区
    handleSelectSaleArea(id) {
      let obj = this.addressList.find(vo => vo.id === id);
      this.formParams.publishProvince = obj.id;
      this.formParams.publishProvinceName = obj.areaName;

      this.form.brandId = [];
      this.form.attr = {};
      this.form.unit = [];
      this.form.config = [];
      this.form.deliveryMethod = [];
      this.form.purchaseVol = [];
      this.form.strongLevel = [];
      this.propertyList = [];

      this.selectedOptions = [];
      this.admixtureFactList = [];
      this.strongFactList = [];
      this.brandList = [];
      this.propertyList = [];
      this.selectedAdmix = [];
      this.formParams.concreteList = [];
      this.formParams.itemList = [];
      this.phoneValidate = true;
      this.numValidate = true;
      this.tdList = [0];
      this.formType = 0;
      this.classificationsOptions = [{}];
      this.$nextTick(() => {
        this.getClassificationsData(id);
      });
    },
    // 选择分类
    sortChange(value, idx) {
      if (!value) {
        return;
      }
      this.form.brandId[idx] = "";
      this.form.unit[idx] = "";
      this.form.purchaseVol[idx] = "";

      let catObj = this.getCatLabel(this.classificationsOptions, value);
      if (catObj.fcatName == "商砼") {
        this.propertyList[idx] = null;
        this.formParams.itemList[idx] = null;
      } else {
        this.admixtureFactList[idx] = [];
        this.strongFactList[idx] = [];
        this.selectedAdmix[idx] = [];
      }
      this.classificationsOptions.forEach(item => {
        if (item.id === value[0]) {
          if (item.name === "商砼") {
            this.formParams.itemList[idx] = {};
            this.formParams.itemList[idx] = catObj;
            this.formParams.itemList[idx].sort = idx;
            this.shangTong[idx] = item;
            this.form.unit[idx] = "立方米";
            this.formParams.itemList[idx].unit = "立方米";
            this.getLevetData(idx);
            this.getAdditiveData(idx);
            this.form.config[idx] = 2;
            this.form.deliveryMethod[idx] = 1;
            this.formParams.itemList[idx].deliveryMethod = 1;
            this.formParams.itemList[idx].config = 2;
          } else {
            // if(!this.formParams.itemList[idx] || JSON.stringify(this.formParams.itemList[idx]) === '{}'){
            //   this.formParams.itemList[idx] = {}
            // }
            this.formParams.itemList[idx] = {};
            this.formParams.itemList[idx].fcategory = catObj.fcategory;
            this.formParams.itemList[idx].fcatName = catObj.fcatName;
            this.formParams.itemList[idx].scategory = catObj.scategory;
            this.formParams.itemList[idx].scatName = catObj.scatName;
            this.formParams.itemList[idx].tcategory = catObj.tcategory;
            this.formParams.itemList[idx].tcatName = catObj.tcatName;
            this.formParams.itemList[idx].attrList = [];
            this.formParams.itemList[idx].sort = idx;

            this.shangTong[idx] = "";
            this.getBrandData(value[value.length - 1], idx); // 获取品牌选择分类
            this.getProperty(value[value.length - 1], idx); // 获取属性选择分类
          }
        }
      });
    },
    // 选择属性
    handleSelectAttr(value, idx, pId, i) {
      this.$set(this.form, `attr[${idx}-${pId}]`, value);
      let obj = this.propertyList[idx].find(item => item.propertyId === pId);
      let valIndex = obj.data.indexOf(value);
      let _index = idx;
      if (!this.formParams.itemList[idx].attrList[i]) {
        this.formParams.itemList[idx].attrList[i] = {};
      }
      this.formParams.itemList[idx].attrList[i].paramId = obj.propertyId;
      this.formParams.itemList[idx].attrList[i].paramName = obj.name;
      this.formParams.itemList[idx].attrList[i].paramValId = obj.id[valIndex];
      this.formParams.itemList[idx].attrList[i].paramValName = value;
    },
    // 选择品牌
    handleBrand(value, idx) {
      if (!value) {
        return;
      }
      let obj = {};
      if (this.brandList[idx] && this.brandList[idx].length) {
        for (let i = 0; i < this.brandList[idx].length; i++) {
          obj = this.brandList[idx][i].options.find(v => v.id === value);
          if (obj && obj.id) {
            break;
          }
        }
        if (JSON.stringify(obj) == "{}") {
          return;
        }
        this.form.brandId[idx] = value;
        this.formParams.itemList[idx].brandId = obj.id;
        this.formParams.itemList[idx].brandName = obj.brandName;
      }
    },
    // 选择商砼强度
    handleStrong(value, idx) {
      this.formParams.itemList[idx].strongLevel = value;
    },
    // 设置采购量
    updatePurchaseNum(val, idx) {
      let value = Number(val);
      if (!this.form.unit[idx]) {
        this.$message({
          message: "请先选择单位",
          type: "warning"
        });
        return;
      }
      let obj = Unit(this.form.unit[idx]);
      if (isNaN(value)) {
        this.$message({
          message: "请输入数字",
          type: "warning"
        });
        return;
      }
      if (value < obj.min || value > obj.max) {
        this.$message({
          message: `采购量在${obj.min}~${obj.max}之间`,
          type: "warning"
        });
        this.numValidate = false;
        return;
      }
      this.numValidate = true;
      this.formParams.itemList[idx].num = value;
    },
    // 选择单位
    handleUnit(value, idx) {
      this.formParams.itemList[idx].unit = value;
    },
    // 选择商砼服务
    handleDeliveryMethod(value, idx) {
      this.formParams.itemList[idx].deliveryMethod = value;
    },
    // 选择商砼配置
    handleConfig(value, idx) {
      this.formParams.itemList[idx].config = value;
    },
    // 获取分类label
    getCatLabel(arr, ids) {
      let obj = {};
      if (ids && ids[0]) {
        let parent = arr.find(p => p.id === ids[0]);
        let childOne = {};
        let childTwo = {};
        if (parent && parent.children && parent.children.length && ids[1]) {
          childOne = parent.children.find(child => child.id === ids[1]);
          if (childOne.children && childOne.children.length && ids[2]) {
            childTwo = childOne.children.find(c => c.id === ids[2]);
          }
        }
        obj.fcategory = parent.id;
        obj.fcatName = parent.name;
        obj.scategory = childOne.id;
        obj.scatName = childOne.name;
        obj.tcategory = childTwo.id;
        obj.tcatName = childTwo.name;
      }
      return obj;
    },
    // 数据分组
    setGroup(arr) {
      let dataInfo = {};
      arr.forEach((item, index) => {
        let { admixtureName, spec } = item;
        if (!dataInfo[admixtureName]) {
          dataInfo[admixtureName] = {
            admixtureName: item.admixtureName,
            spec: item.admixtureName,
            children: []
          };
        }
        if (item.spec && item.admixtureName) {
          dataInfo[admixtureName].children.push({
            admixtureName: item.admixtureName + "-" + item.spec,
            spec: item.admixtureName + "-" + item.spec
          });
        } else {
          dataInfo[admixtureName].children = null;
        }
      });
      let list = Object.values(dataInfo);
      return list;
    },
    // 删除分类
    delTr(key) {
      this.form.brandId[key] = [];
      this.brandList[key] = [];
      this.selectedOptions[key] = [];
      this.admixtureFactList[key] = [];
      this.strongFactList[key] = [];
      this.selectedAdmix[key] = [];
      this.propertyList[key] = null;
      this.formParams.itemList[key] = null;
      // this.propertyList.splice(key, 1)
      // this.formParams.itemList.splice(key, 1)
      this.tdList.splice(this.tdList.indexOf(key), 1);
    },
    // 添加一行
    addMore() {
      this.tdList.push(this.tdList[this.tdList.length - 1] + 1);
    },
    // 选择收货地区
    handleAddress(value) {
      let parent = this.addressOptions.find(item => item.id === value[0]);
      let child2 = parent.children.find(item => item.id === value[1]);
      let child3 = child2.children.find(item => item.id === value[2]);
      this.formParams.provinceNo = parent.id;
      this.formParams.provinceName = parent.name;
      this.formParams.cityNo = child2.id;
      this.formParams.cityName = child2.name;
      this.formParams.districtNo = child3.id;
      this.formParams.districtName = child3.name;
    },
    // 文件上传
    beforeUpload(file) {
      this.imgLoad = true;
      let reg = new RegExp(".+\.")
      // var FileExt = file.name.replace(/.+\./, "");
      var FileExt = file.name.replace(reg, "");
      var isLt2M = file.size / 1024 / 1024 < 10;
      if (
        "rar,zip,doc,docx,pdf,jpg,png,jpeg".indexOf(FileExt.toLowerCase()) ===
        -1
      ) {
        this.$message({
          message:
            "上传失败，请上传后缀名为.rar.zip.doc.docx.pdf.jpg.png.jpeg的附件！",
          type: "warning"
        });
        this.imgLoad = false;
        return false;
      }
      if (!isLt2M) {
        this.$message({
          type: "warning",
          message: "可上传不超过10M的文件!"
        });
        this.imgLoad = false;
        return false;
      }
    },
    onError(err, file, fileList) {
      console.log("error");
      let error = String(err);
      error = error.slice(error.indexOf("{"), error.length);
      error = JSON.parse(error);
      // this.formParams.filePath = [];
      const index = this.fileList.findIndex(item => {
        return item.url === file.url;
      });
      this.fileList.splice(index, 1);
      this.imgLoad = false;
      this.$message.error(error.msg);
    },
    // 图片上传前校验
    /*beforeUpload(file){
        const isJPG = file.type === 'image/jpeg'
        const isJPG2 = file.type === 'image/png'
        const isJPG3 = file.type === 'image/jpg'
        const isJPG4 = file.type === 'image/bmp'
        const isLt500Kb = (file.size / 1024) < 500

        if (!(isJPG || isJPG2 || isJPG3 || isJPG4)) {
          this.$message.error('上传的图片格式不支持，请上传.bmp .png .jpg .jpeg格式')
        }
        if (!isLt500Kb) {
          this.$message.error('上传图片大小不能超过500kb')
        }
        return (isJPG || isJPG2 || isJPG3 || isJPG4) && isLt500Kb
      },*/
    // 图片上传成功
    /*uploadSuccess(response, file, fileList){
        this.descImg = response.data
        this.formParams.img = this.descImg
      },*/
    /*handleRemove(file, fileList) {
      },*/
    // 预览图片
    /*handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },*/
    // 选择报价形式
    handleQuotationMethod(value) {
      this.formParams.quotationMethod = value.join(",");
    },
    showWarn() {
      this.$message({
        message: "只能上传一张图片",
        type: "warning"
      });
    },
    // 查询供应商
    remoteMethod(query) {
      if (query !== "") {
        this.shopOptionList = [];
        this.loading = true;
        let nos = [];
        if (this.shopList.length) {
          this.shopList.forEach(item => {
            nos.push(item.no);
          });
        }
        nos.push(this.userInfo.companyNo);
        let params = {
          name: query,
          nos: nos
        };
        setTimeout(async () => {
          // 搜索接口
          let res = await apiInquiryPrice.searchCompany(params);
          this.shopOptionList = res.data.data;
          this.loading = false;
        }, 0);
      } else {
        this.shopOptionList = [];
      }
    },
    handleClear() {
      this.form.shop = "";
      this.shopOptionList = [];
    },
    // 添加供应商
    addShop() {
      console.log("userInfo:", this.userInfo);
      if (!this.form.shop) {
        return;
      }
      let list = this.shopOptionList.find(item => item.no === this.form.shop);
      let checkList = this.shopList.find(item => item.no === this.form.shop);
      console.log(list);
      if (checkList && checkList.no) {
        this.$message({
          message: "已添加过该供应商，请选择其它供应商",
          type: "warning"
        });
        return;
      }

      this.formParams.inviteCompanyList.push({
        flag: list.flag,
        provinceNo: list.provinceNo,
        provinceName: list.provinceName,
        supplier: list.no,
        supplierName: list.name
      });
      this.shopList.push(list);
      this.handleClear();
    },
    // 删除供应商
    delShop(index) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.shopList.splice(index, 1);
          this.formParams.inviteCompanyList.splice(index, 1);
        })
        .catch(() => {});
    },
    // 获取三级地址列表
    async getAllAreaList() {
      let arr = [];
      let res = await apiHome.getAreas();
      console.log(res);
      this.addressOptions = this.getTreeList(res.data.data);
    },
    // 递归方法
    getTreeList(data) {
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeList(data[i].children);
        }
      }
      return data;
    },
    checkPhone(value) {
      if (!/^1[3456789]\d{9}$/.test(value.target.value)) {
        this.$message({
          message: "手机号格式不正确",
          type: "warning"
        });
        this.phoneValidate = false;
      } else {
        this.phoneValidate = true;
      }
    },
    addDateTime(val) {
      if (val) {
        let datetime = new Date(val);
        let dateNew = datetime.setDate(datetime.getDate() + 1);
        // 23:59:59
        // console.log('datetime2222:', Number(new Date(dateNew)) - 1);
        return Number(new Date(dateNew) - 1);
      }
    },
    getLonLat() {
      const address = `${this.formParams.provinceName}${this.formParams.cityName}${this.formParams.districtName}`;
      this.getLng(address, this.formParams.detailAddress, false);
    },
    // 提交保存
    onSubmit() {
      // if(!this.formParams.publishProvince || !this.formParams.publishProvinceName){
      //   this.$message({
      //     message: '请选择发布地区',
      //     type: 'warning'
      //   });
      //   return
      // }
      console.log("submit:", this.formParams);

      if (!this.formParams.itemList.length) {
        this.$message({
          message: "请选择采购商品",
          type: "warning"
        });
        return;
      } else {
        for (let i = 0; i < this.formParams.itemList.length; i++) {
          if (
            this.formParams.itemList[i] &&
            !JSON.stringify(this.formParams.itemList[i]) != "{}"
          ) {
            if (
              !this.formParams.itemList[i].fcategory ||
              !this.formParams.itemList[i].scategory ||
              !this.formParams.itemList[i].tcategory
            ) {
              this.$message({
                message: "商品分类不能为空",
                type: "warning"
              });
              return;
            }
            if (
              this.propertyList[i] &&
              this.formParams.itemList[i] &&
              this.formParams.itemList[i].attrList
            ) {
              if (
                this.formParams.itemList[i].attrList.length !==
                this.propertyList[i].length
              ) {
                this.$message({
                  message: "商品属性未选择完善",
                  type: "warning"
                });
                return;
              }
            }
            if (this.formParams.itemList[i].fcatName === "商砼") {
              if (!this.formParams.itemList[i].strongLevel) {
                this.$message({
                  message: "商砼强化等级未选择",
                  type: "warning"
                });
                return;
              }
              if (
                !this.formParams.itemList[i].admixtureFactList ||
                !this.formParams.itemList[i].admixtureFactList.length
              ) {
                this.$message({
                  message: "商砼外加剂未选择",
                  type: "warning"
                });
                return;
              }
              if (!this.formParams.itemList[i].config) {
                this.$message({
                  message: "商砼配置未选择",
                  type: "warning"
                });
                return;
              }
              if (!this.formParams.itemList[i].deliveryMethod) {
                this.$message({
                  message: "商砼服务未选择",
                  type: "warning"
                });
                return;
              }
            }

            if (!this.formParams.itemList[i].unit) {
              this.$message({
                message: "请选择计量单位",
                type: "warning"
              });
              return;
            }
            if (!this.numValidate || !this.formParams.itemList[i].num) {
              this.$message({
                message: "请输入采购量或输入不正确",
                type: "warning"
              });
              return;
            }
          }
        }
      }

      if (!this.formParams.deadlineTime) {
        this.$message({
          message: "报价截止时间不能为空",
          type: "warning"
        });
        return;
      }
      if (
        !this.formParams.provinceNo ||
        !this.formParams.cityNo ||
        !this.formParams.districtNo
      ) {
        this.$message({
          message: "请选择收货省市区",
          type: "warning"
        });
        return;
      }
      if (!this.formParams.detailAddress) {
        this.$message({
          message: "请选择收货详细地址",
          type: "warning"
        });
        return;
      }
      if (!this.formParams.taxRate) {
        this.$message({
          message: "发票税率不能为空",
          type: "warning"
        });
        return;
      } else if (parseFloat(this.formParams.taxRate) > 100) {
        this.$message({
          message: "发票税率不能大于100%",
          type: "warning"
        });
        return;
      }
      /*if(!this.formParams.receivingTime){
          this.$message({
            message: '收货日期不能为空',
            type: 'warning'
          });
          return
        }*/
      if (
        !this.formParams.contacts ||
        !this.formParams.contactNumber ||
        !this.phoneValidate
      ) {
        this.$message({
          message: "联系信息姓名或手机号不能为空或手机号格式错误",
          type: "warning"
        });
        return;
      }
      if (this.formParams.SaleType == 2 || this.formParams.SaleType == 3) {
        // if(!this.formParams.inviteCompanyList.length){
        //   this.$message({
        //     message: '请添加您要邀请的供应商',
        //     type: 'warning'
        //   });
        //   return
        // }
        if (!this.shopList.length) {
          this.$message({
            message: "请添加您要邀请的供应商",
            type: "warning"
          });
          return;
        }
      }

      let newList = this.formParams.itemList.filter(item => item !== null);
      for (let i = 0; i < newList.length; i++) {
        newList[i].sort = i;
      }
      let obj = {
        concreteList: []
      };
      for (let i = 0; i < newList.length; i++) {
        if (newList[i] && newList[i].fcatName === "商砼") {
          obj.concreteList.push(newList[i]);
        }
      }

      // this.formParams.deadlineTime = Number(this.formParams.deadlineTime)
      this.formParams.deadlineTime = this.addDateTime(
        this.formParams.deadlineTime
      );
      this.formParams.publishUserName = this.userInfo.realName;
      // this.formParams.filePath = this.fileList;
      this.formParams.filePath = this.fileList.filter(item => {
        if (item.url) {
          return item;
        }
      });
      let { ...params } = { ...this.formParams };

      console.log("this.formParams.filePath", this.formParams.filePath);
      const arrayList = [];
      if (this.shopList.length) {
        this.shopList.forEach(item => {
          arrayList.push({
            flag: item.flag,
            provinceNo: item.provinceNo,
            provinceName: item.provinceName,
            supplier: item.no,
            supplierName: item.name
          });
        });
      }
      params.inviteCompanyList = arrayList;
      params.type = params.SaleType;
      params.concreteList = obj.concreteList;
      params.itemList = newList.filter(item => item.fcatName !== "商砼");
      this.subLoading = true;
      this.$nextTick(async () => {
        let res = await apiInquiryPrice.savePublish(params);
        if (res.data.code === 200) {
          this.detailId = res.data.data;
          this.subLoading = false;
          this.addSuccessDialogVisible = true;
          this.resetFlag = false
          setTimeout(() => {
            this.resetFlag = true
          })
          if (
            this.$route &&
            this.$route.path &&
            this.$route.path == "/demandHallList"
          ) {
            this.$store.commit("SET_REFRESH", true);
          }
        } else {
          // for(let i= 0;i<this.formParams.concreteList.length;i++){
          //   this.formParams.itemList.splice(this.formParams.concreteList[i].sort, 0, this.formParams.concreteList[i])
          //   this.formParams.concreteList.splice(i, 1)
          // }
          this.subLoading = false;
          this.$message({
            message: res.data.msg,
            type: "error"
          });
        }
      });
    },
    delEmpty(arr) {
      let newArr = arr;
      for (let i = 0; i < newArr.length; i++) {
        if (!newArr[i]) {
          newArr.splice(i, 1);
        }
      }
      return newArr;
    },
    // 获取详情数据
    async getDetailData() {
      let res = await apiInquiryPrice.getDetail(this.detailId);
      if (res.data.code === 200) {
        this.details = res.data.data;
        // this.getClassificationsData(this.details.publishProvince)
      }
    },
    goDetail() {
      this.$router.push({
        path: "/InquiryPriceDetail",
        query: { no: this.detailId }
      });
    },
    // 分类数据初始化排序
    sort(arr) {
      let newArr = [];
      arr.forEach((item, index) => {
        newArr.splice(item.sort, 0, item);
      });
      return newArr;
    },
    // 用户中心-编辑-初始化
    init() {
      let { ...initData } = this.details;
      let [...proAttr] = [
        ...this.details.itemList,
        ...this.details.concreteList
      ];
      let arrs = this.sort(proAttr);
      this.tdList = [];
      for (let i = 0; i < arrs.length; i++) {
        this.tdList.push(i);
        let category = [
          arrs[i].fcategory,
          arrs[i].scategory,
          arrs[i].tcategory
        ];
        this.selectedOptions[arrs[i].sort] = category;
        this.sortChange(category, i);
        this.form.purchaseVol[arrs[i].sort] = arrs[i].num;
        this.form.brandId[arrs[i].sort] = arrs[i].brandId;
        this.form.unit[arrs[i].sort] = arrs[i].unit;
        this.handleUnit(arrs[i].unit, arrs[i].sort);
        this.updatePurchaseNum(arrs[i].num, arrs[i].sort);
        if (arrs[i].fcatName !== "商砼") {
          if (arrs[i].attrList && arrs[i].attrList.length) {
            arrs[i].attrList.forEach((attr, index) => {
              this.form.attr[`${arrs[i].sort}-${attr.paramId}`] =
                attr.paramValName;
              this.formParams.itemList[arrs[i].sort].attrList.push({
                paramId: attr.paramId,
                paramName: attr.paramName,
                paramValId: attr.paramValId,
                paramValName: attr.paramValName
              });
            });
          }
        } else {
          this.form.strongLevel[arrs[i].sort] = arrs[i].strongLevel;
          this.form.config[arrs[i].sort] = arrs[i].config;
          this.form.deliveryMethod[arrs[i].sort] = arrs[i].deliveryMethod;
          let admix = [];
          if (arrs[i].admixtureFactList && arrs[i].admixtureFactList) {
            let admix = [];
            arrs[i].admixtureFactList.forEach((attr, index) => {
              if (!attr.spec) {
                admix[index] = [attr.admixtureName];
              } else {
                admix[index] = [
                  attr.admixtureName,
                  attr.admixtureName + "-" + attr.spec
                ];
              }
            });
            this.$set(this.selectedAdmix, arrs[i].sort, admix);
            this.admixChange(admix, arrs[i].sort);
          }
          this.handleStrong(arrs[i].strongLevel, arrs[i].sort);
          this.handleConfig(arrs[i].config, arrs[i].sort);
          this.handleDeliveryMethod(arrs[i].deliveryMethod, arrs[i].sort);
        }
      }

      // this.form.publishProvince = Number(initData.publishProvince)
      this.form.radio = initData.type;
      let check = initData.quotationMethod
        ? initData.quotationMethod.split(",")
        : [];
      this.formParams.quotationMethod = initData.quotationMethod;
      // this.formParams.publishProvince = initData.publishProvince
      // this.formParams.publishProvinceName = initData.publishProvinceName
      this.formParams.provinceNo = initData.provinceNo;
      this.formParams.provinceName = initData.provinceName;
      this.formParams.cityNo = initData.cityNo;
      this.formParams.cityName = initData.cityName;
      this.formParams.districtNo = initData.districtNo;
      this.formParams.districtName = initData.districtName;
      // this.formParams.transactionMethod = initData.transactionMethod
      this.formParams.inviteCompanyList = initData.inviteCompanyList;
      if (check.length > 1) {
        this.form.check = [Number(check[0]), Number(check[1])];
      } else if (check.length === 1) {
        this.form.check.push(Number(check[0]));
      }
      this.form.receiptAddress = [
        Number(initData.provinceNo),
        Number(initData.cityNo),
        Number(initData.districtNo)
      ];
      this.formParams.describe = initData.describe;
      this.formParams.img = initData.img;
      this.formParams.id = initData.id;
      this.formParams.no = this.detailId;
      this.dialogImageUrl = initData.img;
      // if(initData.img){
      //   this.fileList.push({
      //     name: 'desc.png',
      //     url: initData.img
      //   })
      // }
      this.formParams.type = initData.type;
      this.formParams.SaleType = initData.SaleType;
      this.formParams.deadlineTime = initData.deadlineTime;
      this.formParams.receivingTime = initData.receivingTime;
      this.formParams.contacts = initData.contacts;
      this.formParams.qualityDesc = initData.qualityDesc;
      this.formParams.featuresDesc = initData.featuresDesc;
      this.formParams.contactNumber = initData.contactNumber;
      if (initData.inviteCompanyList.length) {
        initData.inviteCompanyList.forEach(item => {
          this.shopList.push({
            id: item.id,
            no: item.supplier,
            name: item.supplierName,
            provinceNo: item.provinceNo,
            provinceName: item.provinceName
          });
        });
      }
      // this.formLoading = false
    },
    // PC-编辑-初始化
    pcInit() {
      this.tdList = [0];
      let category = [
        this.detailPc.fcategory,
        this.detailPc.scategory,
        this.detailPc.tcategory
      ];
      this.selectedOptions[0] = category;
      this.sortChange(category, 0);

      console.log("detailPc:", this.detailPc);
      this.formParams.contacts = this.userInfo.realName;
      this.formParams.contactNumber = this.userInfo.telPhone;

      // this.formParams.publishProvince = this.detailPc.publishProvince
      // this.formParams.publishProvinceName = this.detailPc.publishProvinceName
      if (this.detailPc.inviteCompanyList) {
        this.formParams.inviteCompanyList = this.detailPc.inviteCompanyList;
      } else {
        this.formParams.inviteCompanyList = [];
      }
      // this.formParams.type = 2

      //询价方式
      // if(this.detailPc.SaleType) {
      //   this.formParams.SaleType = this.detailPc.SaleType
      // } else {
      //   this.formParams.SaleType = 1
      // }

      if (this.detailPc.type) {
        // this.formParams.type = this.detailPc.type
        this.formParams.SaleType = this.detailPc.type;
      } else {
        // this.formParams.type = 2
        this.formParams.SaleType = 1;
      }
      //发票税率
      if (this.detailPc.taxRate) {
        this.formParams.taxRate = this.detailPc.taxRate;
      }
      //质量要求
      if (this.detailPc.qualityDesc) {
        this.formParams.qualityDesc = this.detailPc.qualityDesc;
      }
      //特征描述
      if (this.detailPc.featuresDesc) {
        this.formParams.featuresDesc = this.detailPc.featuresDesc;
      }
      this.form.purchaseVol[0] = this.detailPc.num;
      this.form.brandId[0] = this.detailPc.brandId;
      this.form.unit[0] = this.detailPc.unit;
      if (this.detailPc.unit) {
        this.handleUnit(this.detailPc.unit, 0);
      }
      if (this.detailPc.num) {
        this.updatePurchaseNum(this.detailPc.num, 0);
      }
      if (this.detailPc.fcatName !== "商砼") {
        /*if(this.detailPc.attrList && this.detailPc.attrList.length){
             this.detailPc.attrList.forEach((attr, index)=>{
               this.form.attr[`0-${attr.id}`] = attr.val
               this.formParams.itemList[0].attrList.push({
                 "paramId": attr.id,
                 "paramName": '',
                 "paramValId": attr.valId,
                 "paramValName": attr.val
               })
             })
           }*/
        if (this.detailPc.attrList && this.detailPc.attrList.length) {
          this.detailPc.attrList.forEach((attr, index) => {
            this.form.attr[`0-${attr.paramId}`] = attr.paramValName;
            this.formParams.itemList[0].attrList.push({
              paramId: attr.paramId,
              paramName: attr.paramName,
              paramValId: attr.paramValId,
              paramValName: attr.paramValName
            });
          });
        }
        console.log("商品attr:", this.form);
      } else {
        this.form.strongLevel[0] = this.detailPc.strongLevel;
        this.form.config[0] = this.detailPc.config;
        console.log("form.config:", this.form.config[0]);
        this.form.deliveryMethod[0] = this.detailPc.deliveryMethod;
        console.log("form.deliveryMethod:", this.form.deliveryMethod[0]);
        let admix = [];
        if (
          this.detailPc.admixtureFactList &&
          this.detailPc.admixtureFactList
        ) {
          let admix = [];
          this.detailPc.admixtureFactList.forEach((attr, index) => {
            if (!attr.spec) {
              admix[index] = [attr.admixtureName];
            } else {
              admix[index] = [
                attr.admixtureName,
                attr.admixtureName + "-" + attr.spec
              ];
            }
          });
          this.$set(this.selectedAdmix, 0, admix);
          this.admixChange(admix, 0);
        }
        this.handleStrong(this.detailPc.strongLevel, 0);
        this.handleConfig(this.detailPc.config, 0);
        this.handleDeliveryMethod(this.detailPc.deliveryMethod, 0);
      }
      // this.form.publishProvince = Number(this.detailPc.publishProvince)
      setTimeout(() => {
        this.handleBrand(this.detailPc.brandId, 0);
      }, 0);
      if (
        this.detailPc.inviteCompanyList &&
        this.detailPc.inviteCompanyList.length
      ) {
        this.detailPc.inviteCompanyList.forEach(item => {
          this.shopList.push({
            no: item.supplier,
            name: item.supplierName,
            provinceNo: item.provinceNo,
            provinceName: item.provinceName
          });
          let noRepeat = () => {
            let map = new Map();
            for (let item of this.shopList) {
              if (!map.has(item.no)) {
                map.set(item.no, item);
              }
            }
            return [...map.values()];
          };
          this.shopList = noRepeat();
        });
      }
    },
    // 前台商品数据
    async getEnquiryItemDetails() {
      let res = await apiInquiryPrice.getEnquiryItemDetails(
        this.$route.query.id
      );
      if (res.data.code === 200) {
        this.detailPc = res.data.data;
        // this.getClassificationsData(Number(res.data.data.publishProvince))
      }
    },
    // 前台商砼数据
    async getEnquiryConcreteDetails() {
      let res = await apiInquiryPrice.getEnquiryConcreteDetails(
        this.$route.query.id
      );
      if (res.data.code === 200) {
        this.detailPc = res.data.data;
        // this.getClassificationsData(Number(res.data.data.publishProvince))
      }
    },
    //初始化高德地图
    GaodeMap() {
      var that = this;
      this.mapAMap = new AMap.Map("containerMap", {
        resizeEnable: true,
        showIndoorMap: false, //关闭室内地图
        zoom: 12
      });
      AMap.plugin("AMap.Geocoder", function() {
        that.geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
        });
      });
      AMap.plugin("AMap.Geolocation", function() {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：5s
          buttonPosition: "RB", //定位按钮的停靠位置
          buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
          showButton: false,
          markerOptions: {
            //自定义定位点样式，同Marker的Options
            offset: new AMap.Pixel(-18, -36),
            content:
              '<img src="https://a.amap.com/jsapi_demos/static/resource/img/user.png" style="width:36px;height:36px"/>'
          },
          circleOptions: {
            //定位精度圈的样式
            strokeColor: "#0093FF",
            noSelect: true,
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: "#02B0FF",
            fillOpacity: 0.25
          }
        });
        that.mapAMap.addControl(geolocation);
      });
    },
    //地理转换
    getLng(value, deatil, type) {
      var that = this;
      if (type) {
        //省市区获得定位地点
        //选择城市
        const addressQ = `${value}市人民政府`;
        if (this.markerMap) {
          this.mapAMap.remove(this.markerMap);
        }
        this.geocoder.getLocation(addressQ, function(status, result) {
          if (status === "complete" && result.geocodes.length) {
            console.log(result);
            // that.formParams.detailAddress = result.geocodes[0].formattedAddress;
            that.formParams.longitude = result.geocodes[0].location.lng;
            that.formParams.latitude = result.geocodes[0].location.lat;

            // that.addressObj.address = result.geocodes[0].formattedAddress;
            // that.addressObj.lng = result.geocodes[0].location.lng;
            // that.addressObj.lat = result.geocodes[0].location.lat;

            // that.PriData.province =
            //   result.geocodes[0].addressComponent.province;
            // that.PriData.city = result.geocodes[0].addressComponent.city;
            // that.PriData.district =
            //   result.geocodes[0].addressComponent.district;
            // that.setCenter();
          } else {
            that.$message.error("根据地址查询经纬度失败");
          }
        });
      } else {
        //详细地址获取
        //点击搜索
        if (!value) {
          that.$message.error("请输入收货详细地址");
          return;
        }
        if (this.markerMap) {
          this.mapAMap.remove(this.markerMap);
        }
        // const cityW = `${this.cityName}市${value}`;
        const cityW = `${value}${deatil}`;
        this.geocoder.getLocation(cityW, function(status, result) {
          if (status === "complete" && result.geocodes.length) {
            console.log(result);
            // return
            // that.formParams.detailAddress = result.geocodes[0].formattedAddress;
            that.formParams.longitude = result.geocodes[0].location.lng;
            that.formParams.latitude = result.geocodes[0].location.lat;

            // that.addressObj.address = result.geocodes[0].formattedAddress;
            // that.addressObj.lng = result.geocodes[0].location.lng;
            // that.addressObj.lat = result.geocodes[0].location.lat;

            // that.PriData.province =
            //   result.geocodes[0].addressComponent.province;
            // that.PriData.city = result.geocodes[0].addressComponent.city;
            // that.PriData.district =
            //   result.geocodes[0].addressComponent.district;
            // that.setCenter();
          } else {
            that.$message.error("根据地址查询经纬度失败");
            that.getLng(value, deatil, true);
          }
        });
      }
    }
  },
  created() {
    this.$set(this.enquiryDetails, "type", 1);
    this.$set(this.enquiryDetails, "contacts", this.userInfo.realName);
    this.$set(this.enquiryDetails, "contactNumber", this.userInfo.telPhone);
    this.$set(this.enquiryDetails, "time", new Date());
    this.getClassificationsData();
    this.getUnitListData();
    this.getAllAreaList();
    let boll1 = this.$store.state.user.userRole == 1; //0个人1企业
    let boll2 =
      this.$store.state.user.userRole == 0 && this.$store.state.user.relevance; //true关联false未关联企业
    if (
      (boll1 || boll2) &&
      this.$store.state.user.companyNo &&
      this.$store.state.user.companyNo != "0"
    ) {
      this.IsPerson = true;
    } else {
      this.IsPerson = false;
    }
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.state.user;
    }
  }
};
