//
//
//
//
//
//
//

export default {
  components: {
    TabMenuList: () => import('@/pages/Search/components/tabMenuList/index.vue')
  },
  data() {
    return {
      list: [
        { name: "商品询价", value: 0, path: "/Inquiry/GoodsAsk" },
        { name: "租赁询价", value: 1, path: "/Inquiry/LeaseAsk" }
      ]
    };
  },
  created() {},
  mounted() {},
  methods: {
    clickMenuItem(data) {
      // this.tabIndex = data.value;
      this.$router.push(data.path);
    },
  }
};
