//
//
//
//
//
//

export default {
  components: {
    NotData: () => import('@/pages/Inquiry/components/AsknotData.vue')
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
