//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    file: {
      type: Object,
      default: {}
    },
    showDel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pdfUrl: '',
      imgsrc: {
        "rar": require('@/assets/material/zip.png'),
        "zip": require('@/assets/material/zip.png'),
        "pdf": require('@/assets/material/pdf.png'),
        "jpg": require('@/assets/material/img.png'),
        "jpeg": require('@/assets/material/img.png'),
        "png": require('@/assets/material/img.png'),
        "doc": require('@/assets/material/word.png'),
        "docx": require('@/assets/material/word.png')
      }
    }
  },
  filters: {
    toKB(val) {
      return (Number(val) / 1024).toFixed(0);
    }
  },
  methods: {
    cancelHanlder(item) {
      this.$emit('cancel', item)
    },
    previewHandler(data) {
      console.log('previewHandler', data)
      // window.open(url);
      if (data) {
        const addTypeArray = data.split(".");
        const addType = addTypeArray[addTypeArray.length - 1];
        if (addType === "pdf") {
          let routeData = this.$router.resolve({ path: "/insurancePdf", query: { url: data, showBack: false } });
          window.open(routeData.href, '_blank');
        } else if (addType === "doc") {
          window.open(
            `https://view.officeapps.live.com/op/view.aspx?src=${data}`
          );
        } else if (addType === "txt") {
          window.open(`${data}`);
        }
      }
    },
    downLoadHandler() {},
    isPdf(file) {
      return file.fileName.endsWith('pdf') || file.fileName.endsWith('doc');
    },
    getName(file) {
      let reg = new RegExp("(.*\/)*([^.]+).*", 'ig')
      // return file && file.replace(/(.*\/)*([^.]+).*/ig,"$2");
      return file && file.replace(reg,"$2");
    },
    getEnd(file) {
      let reg = new RegExp("(.+\.")
      // return file && file.replace(/.+\./,"");
      return file && file.replace(reg, "");
    },
  }
}
